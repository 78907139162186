.mb-4 {
  margin-bottom: 14px;
}
p {
  font-size: 1rem;
}
.d-flex {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
}
.employee-image .MuiCard-root {
  height: 420px;
}
.employee-image span {
  margin: auto;
  display: flex;
  height: 100%;
}
.employee-image span .ant-upload-list-picture-card {
  margin: auto;
}
.margin-left {
  margin-left: auto;
  margin-top: 30px;
  margin-right: 20px;
}
.margin-left button {
  margin: 0px 0px 0px 20px;
  float: right;
}
.background-image {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  margin-bottom: 24px;
  height: 280px;
  position: relative;
}
.css-before::before {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 82, 73, 0.8);
  top: 0px;
  z-index: 9;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
}
.css-avtar {
  left: 0px;
  right: 0px;
  z-index: 99;
  position: absolute;
  margin-top: 40px;
  right: auto;
  display: flex;
  align-items: center;
  left: 24px;
  bottom: 24px;
}
.avtar-outer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: 'Public Sans', sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  margin-left: auto;
  margin-right: auto;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
}
.user_name {
  margin-left: 24px;
  margin-top: 0px;
  text-align: left;
  color: rgb(255, 255, 255);
}
.user_name h4 {
  color: rgb(255, 255, 255);
}
.lazy-load-image-background {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  filter: blur(0px);
  transition: filter 0.3px;
  transition: filter 0.3s, -webkit-filter 0.3s;
}
.css-tsniyt {
  z-index: 9;
  bottom: 0px;
  width: 100%;
  display: flex;
  position: absolute;
  background-color: rgb(255, 255, 255);
}
.d-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.profile_card {
  height: 450px;
}
.edit_icon {
  position: absolute;
  color: #ffffff;
  z-index: 99;
  position: absolute;
  margin-top: 15px;
  right: auto;
  display: flex;
  align-items: center;
  right: 24px;
  /* bottom: 24px; */
  cursor: pointer;
}

/*  Holiday calendar Pop CSS */
event-color-c {
  display: flex;
  margin: 16px;
  align-items: center;
  cursor: pointer;
}

.event-color-label {
  flex: 1 0 auto;
}

.event-color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 240px;
  background: #5ac8fa;
}

.crud-color-row {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.crud-color-c {
  padding: 3px;
  margin: 2px;
}

.crud-color {
  position: relative;
  min-width: 46px;
  min-height: 46px;
  margin: 2px;
  cursor: pointer;
  border-radius: 23px;
  background: #5ac8fa;
}

.crud-color-c.selected,
.crud-color-c:hover {
  box-shadow: inset 0 0 0 3px #007bff;
  border-radius: 48px;
}

.crud-color:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  color: #f7f7f7;
  font-size: 20px;
  text-shadow: 0 0 3px #000;
  display: none;
}
.event-color-c {
  display: flex;
  margin: 16px;
  align-items: center;
  cursor: pointer;
}
.crud-color-c.selected .crud-color:before {
  display: block;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 44px !important;
  margin-top: 30px;
  align-items: center;
  display: flex;
}
@media (min-width: 900px) {
  .avtar-outer {
    width: 128px;
    height: 128px;
  }
}
