@font-face {
  font-family: "DM_sans_Bold";
  src: url("../assests/fonts/DMSans-Bold.ttf");
}
@font-face {
  font-family: "DM_sans_Medium";
  src: url("../assests/fonts/DMSans-Medium.ttf");
}
@font-face {
  font-family: "DM_sans_Regular";
  src: url("../assests/fonts/DMSans-Regular.ttf");
}
:root {
  --body-font: "DM_sans_Regular";
  --heading-font: "DM_sans_Bold";
  --subheading-font: "DM_sans_Medium";
  --big-font-size: 6.25rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
}
:root {
  --color: #000000;
  --dark-body: #d3d3d9;
  --gray: #999ab8;
  --gray-dark: #343a40;
  --white-color: #ffffff;
  --light-grey: #52526c;
  --dark-heading-color: #d3d3d4;
  --blue: RGBA(0, 122, 244, 0.2);
  --heading-color: #000248;
  /* --dark-theme: #1b1c1e; */
  --dark-theme:#222831;
  --lightest-bg-color: #fafafb;
  --dark-bg-color: #242526;
  --black-color:#000000;
/* --light-theme: rgba(224, 231, 255,100%); */
--light-theme:#e0e7ff;
  /* --light-theme: linear-gradient(to bottom, #fef7e2 0%, #eeeefd 100%); */
  --light-img: url("../assests/img/vector.svg");
  --dark-img: url("../assests/img/sun-01.svg");

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  font-weight: 700;
}
h1 {
  font-size: 3rem !important;
}
p {
  font-size: 1.4rem;
}
a {
  color: #000;
  text-decoration: none;
}
@media only screen and (max-width: 1224px) {
  h1 {
    font-size: 2.5rem !important;
  }
  .process p {
    font-size: 1.2rem;

    line-height: 36px;
  }
}
@media only screen and (max-width: 999px) {
  h1 {
    font-size: 1.8rem !important;
  }
  .process p {
    font-size: 1rem;

    line-height: 26px;
  }
}
@media only screen and (max-width: 756px) {
  h1 {
    font-size: 1.5rem !important;
  }
}