@import './styles/variable.css';
body {
  font-family: var(--body-font);
}
body {
  /* color: var(--color);
  background-color: var(--light-theme); */
  --color: #ffffff;
  --backgroundPrimaryColor: var(--light-theme);
}
body.dark {
  --color: #ffffff;
  --backgroundPrimaryColor: #000000;
}
.bg-color {
  padding-bottom: 70px;
}
.header-right {
  width: calc(100% - 3.5rem);
}
.css-11zwbe3-MuiPaper-root-MuiDialog-paper {
  padding: 20px;
}
.css-11zwbe3-MuiPaper-root-MuiDialog-paper h2 {
  padding-left: 0;
}
.css-11zwbe3-MuiPaper-root-MuiDialog-paper .margin-left {
  margin-right: 0;
}
.d-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3%;
  width: 100%;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.ml-4 {
  margin-left: 20px;
}
